import ReactDOM from 'react-dom/client';

import { CLRouterProvider } from 'anthill-component-library/helpers/master'
import { AppContextProvider } from 'anthill-component-library/contexts/app'
import routes from './components/Routes';
import { AffiliateContextProvider } from './context/AffiliateContext';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AppContextProvider>
    <AffiliateContextProvider>
      <CLRouterProvider router={routes} />
    </AffiliateContextProvider>
  </AppContextProvider>
);
