import { AuthContext } from "anthill-component-library/dist/AuthContext-e2762bed";
import { createContext, useContext, useEffect, useState } from "react";

const AffiliateContext = createContext({})

function AffiliateContextProvider({ children }) {

    const { employee } = useContext(AuthContext)

    const [selectedOrganization, setSelectedOrganization] = useState(null);

    useEffect(() => {
        const savedOrganization = localStorage.getItem("selectedOrganization");

        if (savedOrganization) {
            setSelectedOrganization(savedOrganization);
        } else {
            const employeeData = JSON.parse(localStorage.getItem("employee"));
            if (employeeData && employeeData?.affiliateOrganizations?.length > 0) {
                const initialOrganization = employeeData.affiliateOrganizations[0].affiliateOrganization._id;
                setSelectedOrganization(initialOrganization);
                localStorage.setItem("selectedOrganization", initialOrganization);
            }
        }
    }, [employee]);

    useEffect(() => {
        if (!employee) {
            setSelectedOrganization(null);
            localStorage.removeItem("selectedOrganization");
        }
    }, [employee]);

    return (
        <AffiliateContext.Provider
            value={{
                selectedOrganization,
                setSelectedOrganization
            }}
        >
            {children}
        </AffiliateContext.Provider>
    )
}

export {
    AffiliateContextProvider,
    AffiliateContext
}
